// (function ($) {
//     'use strict';
//     $(document).ready(function () {

//         var bsWrap = $('#bs-wrapper');
//         var bodyH = $('body').outerHeight();
//         var windowH = $(window).height();
//         if(bodyH < windowH){
//             var headerH = $('#bs-header').outerHeight();
//             var footerH = $('#bs-footer').outerHeight();
//             var bsWrapH = windowH - (headerH + footerH);
//             bsWrap.css('min-height', bsWrapH);
//         }

//     });
// } (jQuery));