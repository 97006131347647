var _gsScope = "undefined" != typeof module && module.exports && "undefined" != typeof global ? global : this || window;
(_gsScope._gsQueue || (_gsScope._gsQueue = [])).push(function() {
    "use strict";

    function e(e, t, o, r, n, i) {
        return o = (parseFloat(o) - parseFloat(e)) * n, r = (parseFloat(r) - parseFloat(t)) * i, Math.sqrt(o * o + r * r)
    }

    function t(e) {
        return "string" != typeof e && e.nodeType || (e = _gsScope.TweenLite.selector(e), e.length && (e = e[0])), e
    }

    function o(e, t, o) {
        var r, n, i = e.indexOf(" ");
        return -1 === i ? (r = void 0 !== o ? o + "" : e, n = e) : (r = e.substr(0, i), n = e.substr(i + 1)), r = -1 !== r.indexOf("%") ? parseFloat(r) / 100 * t : parseFloat(r), n = -1 !== n.indexOf("%") ? parseFloat(n) / 100 * t : parseFloat(n), r > n ? [n, r] : [r, n]
    }

    function r(o) {
        if (!o) return 0;
        o = t(o);
        var r, n, i, s, a, f, g, u = o.tagName.toLowerCase(),
            d = 1,
            c = 1;
        "non-scaling-stroke" === o.getAttribute("vector-effect") && (c = o.getScreenCTM(), d = c.a, c = c.d);
        try {
            n = o.getBBox()
        } catch (l) {}
        if (n && (n.width || n.height) || "rect" !== u && "circle" !== u && "ellipse" !== u || (n = {
                width: parseFloat(o.getAttribute("rect" === u ? "width" : "circle" === u ? "r" : "rx")),
                height: parseFloat(o.getAttribute("rect" === u ? "height" : "circle" === u ? "r" : "ry"))
            }, "rect" !== u && (n.width *= 2, n.height *= 2)), "path" === u) s = o.style.strokeDasharray, o.style.strokeDasharray = "none", r = o.getTotalLength() || 0, d !== c && console.log("Warning: <path> length cannot be measured accurately when vector-effect is non-scaling-stroke and the element isn't proportionally scaled."), r *= (d + c) / 2, o.style.strokeDasharray = s;
        else if ("rect" === u) r = 2 * n.width * d + 2 * n.height * c;
        else if ("line" === u) r = e(o.getAttribute("x1"), o.getAttribute("y1"), o.getAttribute("x2"), o.getAttribute("y2"), d, c);
        else if ("polyline" === u || "polygon" === u)
            for (i = o.getAttribute("points").match(h) || [], "polygon" === u && i.push(i[0], i[1]), r = 0, a = 2; a < i.length; a += 2) r += e(i[a - 2], i[a - 1], i[a], i[a + 1], d, c) || 0;
        else("circle" === u || "ellipse" === u) && (f = n.width / 2 * d, g = n.height / 2 * c, r = Math.PI * (3 * (f + g) - Math.sqrt((3 * f + g) * (f + 3 * g))));
        return r || 0
    }

    function n(e, o) {
        if (!e) return [0, 0];
        e = t(e), o = o || r(e) + 1;
        var n = a(e),
            i = n.strokeDasharray || "",
            s = parseFloat(n.strokeDashoffset),
            h = i.indexOf(",");
        return 0 > h && (h = i.indexOf(" ")), i = 0 > h ? o : parseFloat(i.substr(0, h)) || 1e-5, i > o && (i = o), [Math.max(0, -s), Math.max(0, i - s)]
    }
    var i, s = _gsScope.document,
        a = s.defaultView ? s.defaultView.getComputedStyle : function() {},
        h = /(?:(-|-=|\+=)?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi,
        f = -1 !== ((_gsScope.navigator || {}).userAgent || "").indexOf("Edge"),
        g = "codepen",
        u = "DrawSVGPlugin",
        d = String.fromCharCode(103, 114, 101, 101, 110, 115, 111, 99, 107, 46, 99, 111, 109),
        c = String.fromCharCode(47, 114, 101, 113, 117, 105, 114, 101, 115, 45, 109, 101, 109, 98, 101, 114, 115, 104, 105, 112, 47),
        l = function(e) {
            return true;
        }(window ? window.location.host : "");
    i = _gsScope._gsDefine.plugin({
        propName: "drawSVG",
        API: 2,
        version: "0.1.3",
        global: !0,
        overwriteProps: ["drawSVG"],
        init: function(e, t, i, s) {
            if (!e.getBBox) return !1;
            var h, C, p, m, S = r(e) + 1;
            return this._style = e.style, "function" == typeof t && (t = t(s, e)), t === !0 || "true" === t ? t = "0 100%" : t ? -1 === (t + "").indexOf(" ") && (t = "0 " + t) : t = "0 0", h = n(e, S), C = o(t, S, h[0]), this._length = S + 10, 0 === h[0] && 0 === C[0] ? (p = Math.max(1e-5, C[1] - S), this._dash = S + p, this._offset = S - h[1] + p, this._addTween(this, "_offset", this._offset, S - C[1] + p, "drawSVG")) : (this._dash = h[1] - h[0] || 1e-6, this._offset = -h[0], this._addTween(this, "_dash", this._dash, C[1] - C[0] || 1e-5, "drawSVG"), this._addTween(this, "_offset", this._offset, -C[0], "drawSVG")), f && (m = a(e), C = m.strokeLinecap, "butt" !== C && C !== m.strokeLinejoin && (C = parseFloat(m.strokeMiterlimit), this._addTween(e.style, "strokeMiterlimit", C, C + 1e-4, "strokeMiterlimit"))), l
        },
        set: function(e) {
            this._firstPT && (this._super.setRatio.call(this, e), this._style.strokeDashoffset = this._offset, this._style.strokeDasharray = 1 === e || 0 === e ? this._offset < .001 && this._length - this._dash <= 10 ? "none" : this._offset === this._dash ? "0px, 999999px" : this._dash + "px," + this._length + "px" : this._dash + "px," + this._length + "px")
        }
    }), i.getLength = r, i.getPosition = n
}), _gsScope._gsDefine && _gsScope._gsQueue.pop()(),
    function(e) {
        "use strict";
        var t = function() {
            return (_gsScope.GreenSockGlobals || _gsScope)[e]
        };
        "function" == typeof define && define.amd ? define(["TweenLite"], t) : "undefined" != typeof module && module.exports && (require("../TweenLite.js"), module.exports = t())
    }("DrawSVGPlugin");