$(document).ready(function () {
	var ip = $('.cbo-price__tab--ip'),
		too = $('.cbo-price__tab--too'),
		sumIp = $('#ip'),
		sumToo =$('#too');

	too.on('click', function () {
		sumToo.removeClass('cbo-price__price--hidden');
		sumIp.addClass('cbo-price__price--hidden');
		ip.removeClass('cbo-price__tab--active');
		$(this).addClass('cbo-price__tab--active');
	});
	ip.on('click', function () {
		sumIp.removeClass('cbo-price__price--hidden');
		sumToo.addClass('cbo-price__price--hidden');
		too.removeClass('cbo-price__tab--active');
		$(this).addClass('cbo-price__tab--active');
	});
})