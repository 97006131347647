$(document).ready(function() {
    var mainNav = $('.cbo-main-nav'),
        logo = $('.cbo-main-nav__logoWrp'),
        list = $('.cbo-main-nav__list'),
        buttonWrp = $('.cbo-main-nav__btnWrp'),
        button = $('.cbo-main-nav__button--top'),
        offsetNav = $('.cbo-main-nav__inner'),
        menuBurger = $('.cbo-main-nav__list'),
        burgerBtn = $('.cbo-main-nav__burgerWrp'),
        closeBurger = $('.cbo-main-nav__item--close'),
        itemBurger = $('.cbo-main-nav__item');
    if ($(window).width() > 992) {
        $(document).on('scroll', function() {
            if ($(this).scrollTop() >= 99) {
                TweenMax.to(mainNav, 0.3, {css:{"height":"40","padding":"5 0 5 0"}});
                TweenMax.to(logo, 0.2, {autoAlpha:0});
                TweenMax.to(list, 0.2, {css:{"margin-top":"8"}});
                TweenMax.to(buttonWrp, 0.2, {css:{"margin-top":"5"}});
                TweenMax.to(button, 0.2, {css:{"padding":"5 25 5 25"}});
                // TweenMax.to(offsetNav, 0.4, {delay: 0.5, x: -50});
            } else {
                TweenMax.to(mainNav, 0.3, {css:{"height":"103","padding":"15 0 15 0"}});
                TweenMax.to(logo, 0.8, {autoAlpha:1});
                TweenMax.to(list, 0.2, {css:{"margin-top":"30px"}});
                TweenMax.to(buttonWrp, 0.2, {css:{"margin-top":"25"}});
                TweenMax.to(button, 0.2, {css:{"padding":"10 25 10 25"}});
                // TweenMax.fromTo(offsetNav, 0.4, {x: -50},{x: 50});
            }
        });
    }
    burgerBtn.on('click',function () {
        TweenMax.to(menuBurger, 0.4, {css:{"left":"0"}});
    })
    closeBurger.on('click',function () {
        TweenMax.to(menuBurger, 0.4, {css:{"left":"-100%"}});
    })
    itemBurger.on('click',function () {
        TweenMax.to(menuBurger, 0.4, {css:{"left":"-100%"}});
    })
})